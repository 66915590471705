import { Component, OnInit } from '@angular/core';
import { BullkEBPOSReturnViewModel, EBOrdersViewModel } from '../eb-orders-class';
import { Subject, Subscription } from 'rxjs';
import { StoreService } from '../../store/store.services';
import { EmployeeServices } from '../../employee/employee-services';
import { NotificationService } from '../../../_services/notification.service';
import { EbService } from '../eb-service.service';
import { ItemViewModel } from '../../store/store-model';
import { debounceTime } from 'rxjs/operators';
import { EBOrderSettlementType } from '../../../_helpers/EB-enum';
import { Location } from '@angular/common';

@Component({
  selector: 'app-bulk-eb-pos',
  templateUrl: './bulk-eb-pos.component.html',
  styleUrls: ['./bulk-eb-pos.component.scss']
})
export class BulkEbPosComponent implements OnInit {
  public _BullkEBPOSReturnViewModel = new BullkEBPOSReturnViewModel();
  ebAccountList: Array<any> = [];
  paymentAccountList: Array<any> = [];
  public searchEvent = new Subject<any>();
  public searchSubcription: Subscription = null;
  fetchedEbOrders: Array<EBOrdersViewModel> = [];
  employeeAccount : any;
toDate = new Date();
  fetchSpinner: boolean = false;
  public loading: boolean = false;
  public maxDate = new Date();
  isMaxValue:boolean = false;
  constructor(
    private _storeService: StoreService,
    private _employeeService: EmployeeServices,
    private _notificationService: NotificationService,
    private _ebOrderService: EbService,
    private location: Location
  ) {
    console.log(this.employeeAccount);
    this._BullkEBPOSReturnViewModel.CreditAccount = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.FK_Tasker_Id = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.DebitAccount = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.FK_Customer_Id = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.Fk_BankAccount = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.GatewayExpenditureAccount = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.FK_Client_Id = new ItemViewModel();
  }

  ngOnInit() {
    this.maxDate.setHours(this.maxDate.getHours() + 5);
    this._BullkEBPOSReturnViewModel.OrderDate = new Date();
    this.handleSearchEvents();
    this.getEBAccountList('','E');
    this.getPaymentAccount('','CaB');
  }
  public getEBAccountList(x: string, event: string) {
    this._storeService.getAccountBySearch(x, event).subscribe(
      (res: any) => {
        console.log("emp list", res);
        if (res) {
          if (event === "E") {
            this.ebAccountList = res;
          }
        }
      },
      (err) => {
        this._notificationService.notify('danger', err.msg);
      }
    );
  }
  public getPaymentAccount(x: string, event: string) {
    this._storeService.getAccountBySearch(x, event).subscribe(
      (res: any) => {
        console.log("cashbank acout", res);
        if (res) {
          if (event === "CaB") {
            this.paymentAccountList = res;
          }
        }
      },
      (err) => {
        this._notificationService.notify("danger", err.msg);
      }
    );
  }
  public handleSearchEvents() {
    this.searchSubcription = this.searchEvent.pipe(
      debounceTime(400)).subscribe((x: any) => {
        console.log(x);
        if (x) {
          if (x.filter == 'FK_Emp_Acc') {
            this.getEBAccountList(x.event, 'E');
          }
          if (x.filter == 'FK_Dabit_Acc') {
            this.getPaymentAccount(x.event, 'CaB');
          }
        }
      });
  }
  public attachfile(event) {
    this._BullkEBPOSReturnViewModel.file = event.target.files[0];
  }
  fetchBulkEBReturns(){
    this.fetchSpinner = true;
    
   let fetchObj ={
     Id:this.employeeAccount.Id,
     ToDate:this.toDate
   }
    this._ebOrderService
      .GetUnPaidEBOrdersPOSByEmployeeId(fetchObj)
      .subscribe((ebOrders:any) => {
        console.log("fetched list", ebOrders);
        this._BullkEBPOSReturnViewModel = ebOrders;
        this.fetchedEbOrders = ebOrders.bulkPaymentList;
        this._BullkEBPOSReturnViewModel.OrderDate = new Date();
       
        this.fetchSpinner = false;
      },error => {
        this.fetchSpinner = false;
      });
  }
  lockRecord(x){
    if(x.OrderAmount === null){
     this._notificationService.notify('danger','Please enter To pay amount to Lock the record');
     return ;
    }
    if(x.OrderAmount > x.OrderAmountCheck){
      this._notificationService.notify('danger','You cannot enter pay amount greater than to order amount');
     return ;
    }
    x.IsLock =!x.IsLock;
    if(x.IsLock){
      this._BullkEBPOSReturnViewModel.TotalEBCharges += x.EBCharges;
      this._BullkEBPOSReturnViewModel.TotalOrderAmount +=x.OrderAmount;
    }
    else{
      this._BullkEBPOSReturnViewModel.TotalEBCharges -= x.EBCharges;
      this._BullkEBPOSReturnViewModel.TotalOrderAmount -=x.OrderAmount;
    }
  }
  public index:number;
  removelistElement(index) {
    this.index = index;
    const x = this.fetchedEbOrders[this.index];
    // this._BullkEBPOSReturnViewModel.TotalOrderAmount -= x.OrderAmount;
    // this._BullkEBPOSReturnViewModel.TotalEBCharges -=x.EBCharges;
    this.fetchedEbOrders.splice(this.index,1);
  }
  AddBulkEBPOS() {
    if (
      this.fetchedEbOrders.find((x) => x.IsLock == false) !=
      null
    ) {
      this.loading = false;
      this._notificationService.notify("danger", "Lock the all record first");
      return;
    }
    this._BullkEBPOSReturnViewModel.FK_Tasker_Id = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.FK_Client_Id = new ItemViewModel();
      this._BullkEBPOSReturnViewModel.SettlementType = EBOrderSettlementType.POS;
      this._BullkEBPOSReturnViewModel.EmployeAccount_Id = this.employeeAccount;
    this.loading = true;
    this._BullkEBPOSReturnViewModel.bulkPaymentList = this.fetchedEbOrders;
    let ebObject: any = Object.assign({}, this._BullkEBPOSReturnViewModel);
    if (this._BullkEBPOSReturnViewModel.OrderDate) {
      ebObject.OrderDate = this._BullkEBPOSReturnViewModel.OrderDate.toDateString();
    }
    this._ebOrderService.AddBulkEBPOS(ebObject).subscribe(
      (res) => {
        this.loading = false;
        this._notificationService.notify("success", "Saved Successfully");
        this.location.back();
      },
      (err) => {
        console.log("error", err);
        this.loading = false;
        this._notificationService.notify("danger", "Something Went Wrong");
      }
    );
  }
  calculateTotals(){
    this._BullkEBPOSReturnViewModel.TotalRecevedFrombank=0;
    this._BullkEBPOSReturnViewModel.TotalOrderAmount =0;
    this.fetchedEbOrders.forEach(x=>{
      if(x.OrderAmount > x.OrderAmountCheck){
        this.isMaxValue = true;
      }
      else{
        this.isMaxValue = false;
      }
     this._BullkEBPOSReturnViewModel.TotalOrderAmount += x.OrderAmount;
    });
    console.log(this.isMaxValue);
   }
}
