import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Config } from '../../_helpers/config.class';
import { CategoryViewModel, CatlogViewModel, ItemDefiningViewModel, Sale_PurchaseMastViewModel, StockAssignHistoryViewModel, Attachments, InstalmentVewModel, VehicleOnRentViewModel, VehicleRentMastViewModel, DepreciationViewModel, RentAgreementInvoiceMastViewModel } from "./store-model";
import { AuthenticationService } from "../../_services/authentication.service";
import { Category } from "../settings/setting.class";
import { Observable } from "rxjs";
import { URLSearchParams } from "url";
import { SingleAdjustmentViewModel } from "./view-sp-invoice/sp-invoice-instalment/sp-invoice-instalment.component";
import { LocalService } from "../../_services/local.service";
import { ItemDepreciationComponent } from "./item-depreciation/item-depreciation.component";

@Injectable()
export class StoreService {
  headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, _authenticationService: AuthenticationService,
    private _localService: LocalService) {
    this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    this.headers.set('Authorization', 'Bearer ' + _authenticationService.getToken())
  }

  public saveCategory(category: CategoryViewModel) {
    return this.http.post(`${Config.getControllerUrl('Store', 'SaveCategory')}`, category);
  }
  public validateCatCode(code: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'ValidateCatCode')}`, { Code: code });
  }
  public saveReturnItem(code: number, date: any, rQty,remarks?) {
    
    return this.http.post(`${Config.getControllerUrl('Store', 'SaveReturnItem')}`, { Id: code, Date: date, returnQty: rQty,Code:remarks });
  }
  public SaveStockForContrectByExcel(obj:any) {
    
    console.log(obj);
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Store", "SaveStockForContrectByExcel")}`,
      formData
    );
  }
  public GetTFExpensefromExcel(obj:any) {
    
    console.log(obj);
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Expense", "GetTFExpensefromExcel")}`,
      formData
    );
  }
  public getEmployeePayaable(id: number) {
    
    return this.http.post(`${Config.getControllerUrl('Employee', 'GetEmployeePayaable')}`, { Id: id });
  }

  public getCategory() {
    return this.http.get(`${Config.getControllerUrl('Store', 'GetCategory')}`);
  }

  public saveCatlog(catlog: CatlogViewModel) {
    return this.http.post(`${Config.getControllerUrl('Store', 'SaveCatlog')}`, catlog);
  }
  public getCatlog(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetCatlogs')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId ,Search: searchTerm});
  }
  public validateCatlogCode(code: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'ValidateCatlogCode')}`, { Code: code });
  }
  /////////////////////////Item-Defining-Section-Services////////////////////////////////////////////////
  public validateItemCode(code: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'ValidateItemCode')}`, { Code: code });
  }
  public getItemCategory() {
    return this.http.get(`${Config.getControllerUrl('Store', 'GetItemCategory')}`);
  }
  public saveItemCategory(obj: Category) {
    return this.http.post(`${Config.getControllerUrl('Setting', 'SaveItemCategory')}`, obj);
  }
  public saveAccountNatuer(obj: Category) {
    return this.http.post(`${Config.getControllerUrl('Setting', 'SaveAccountNatuer')}`, obj);
  }

  public saveExpHeads(obj: Category) {
    return this.http.post(`${Config.getControllerUrl('Setting', 'SaveExpHeads')}`, obj);
  }
  public getFilterdCatlogs(x: string, event: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetFilterdCatlog')}`, { Code: x, Type: event });
  }
  public saveItemDefining(item: ItemDefiningViewModel) {
    return this.http.post(`${Config.getControllerUrl('Store', 'SaveItemDefining')}`, item);
  }
  public RemoveItemDefining(id: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'RemoveItemDefining')}`, { Id: id });
  }
  public GetBatchStockStatus(id: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetBatchStockStatus')}`, { Id: id });
  }
  public GetBatchStockStatusDepp(id: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetBatchStockStatusDepp')}`, { Id: id });
  }
  public getItemList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string ) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetItemList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, Search: searchTerm });
  }
  public getItemById(id: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetItemById')}`, { Id: id });
  }
  public getAccountBySearch(x: string, event: string) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetAccountBySearch')}`, { Code: x, Type: event });
  }
  public getDayBookDetail(x: number) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetDayBookDetail')}`, { Id: x });
  }

  public SaveRentedVechelByExcel(file:any){
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(
      `${Config.getControllerUrl("RentedVehicle", "SaveRentedVechelByExcel")}`,
      formData
    );
  }

  public getItemBySearch(x: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetItemBySearch')}`, { Code: x });
  }
  public GetDepItemBySearch(x: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetDepItemBySearch')}`, { Code: x, Type:'V' });
  }

  public getItemBySearchForReturn(x: string, id: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetItemBySearch')}`, { Code: x, Id: id });
  }
  public getVehcielBySearch(x: string, y: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetItemBySearch')}`, { Code: x, Type: y });
  }
  public getVehcielForRent(x: string, y: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetVehcielForRent')}`, { Code: x, Type: y });
  }
  public savePurchaseInvoice(obj: Sale_PurchaseMastViewModel) {
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Store', 'SavePurchaseInvoice')}`, formData);
  }
  public getSpItemList(spType: number, skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetSPOderList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, SPType: spType, Search: searchTerm });
  }
  public getOderDtl(id: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetOderDtl')}`, { Id: id });
  }
  public getStockStatus(x: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetStockStatus')}`, { Id: x });
  }
  public getStockLastDates(x: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetStockLastDates')}`, { Id: x });
  }
  public getEmpBySearch(x: string) {
    return this.http.post(`${Config.getControllerUrl('Employee', 'GetEmpBySearch')}`, { Code: x });
  }
  public getrentContract(x: string) {
    return this.http.post(`${Config.getControllerUrl('RentAgreement', 'GetRentAgreementList')}`, { Skip: 0, PageSize: 0, Search: x });
  }
  public saveStockassigning(obj: StockAssignHistoryViewModel) {
    return this.http.post(`${Config.getControllerUrl('Store', 'SaveStockassigning')}`, obj);
  }
  public getAssignedStockById(id: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetAssignedStockById')}`, { Id: id });
  }
  public saveAttachment(obj: Attachments) {
    debugger
    // formData.append('docName', obj.DocName);
    // formData.append('remarks', obj.Remarks);
    // formData.append('exp', obj.ExpiryDAte.toDateString());
    // formData.append('item', obj.FK_Item_Id.toString());
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Store', 'SaveAttachment')}`, formData);
  }

  public getAttachments(id: number, type: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetAttachments')}`, { Id: id, Type: type });
  }
  public saveInstalment(obj: InstalmentVewModel) {
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Store', 'SaveInstalment')}`, formData);
  }
  public saveSingleAdjustment(obj: SingleAdjustmentViewModel) {
    const formData = new FormData();
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Store', 'SaveSingleAdjustment')}`, formData);
  }

  public getAdjustmentAccounts(nature): Observable<any> {
    const fd = new FormData();
    fd.append('Nature', nature);
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetAdjustmentAccounts')}`, { Type: nature });
  }
  public getInstalmentHistory(x: number, type: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetInstalmentHistory')}`, { Id: x, Type: type });
  }
  public RemoveInstalment(obj: InstalmentVewModel) {
    const formData = new FormData();

    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Store', 'RemoveInstalment')}`, formData);
  }


  public getAssignedItems(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetAssignedItems')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId , Search: searchTerm});
  }
  public removeStockassigning(id: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'RemoveStockassigning')}`, { Id: id });
  }
  public saveRentedVechel(x: VehicleOnRentViewModel) {
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'SaveRentedVechel')}`, x);
  }
  public getRentedVehcielList(spType: number, skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'GetRentedVehcielList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, SPType: spType, Search: searchTerm });
  }

  public getSuplierRentedVehciel(x: number, td: any, fd: any, bit:boolean) {
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'GetSuplierRentedVehciel')}`, { Id: x, ToDate: td, FromDate: fd, bit });
  }
  public getCustomerRentedVehciel(x: number, td: number, ye:number) {
    return this.http.post(`${Config.getControllerUrl('RentInvoice', 'GetSuplierRentedVehciel')}`, { Id: x, Cid: td, returnQty: ye });
  }
// Exel Improt
  public GetSuplierRentedVehcielExcel(obj:any) {
    
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'GetSuplierRentedVehcielfromExcel')}`, formData);
  }


  public saveRentAgreementInvoice(obj: RentAgreementInvoiceMastViewModel) {
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('RentInvoice', 'SaveRentInvoice')}`, formData);
  }
  public saveVehcielRent(obj: VehicleRentMastViewModel) {
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'SaveVehcielRent')}`, formData);
  }
  public getVehicleRentHistory(spType: number, skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'GetVehicleRentHistory')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, SPType: spType, Search: searchTerm });
  }

  public getRentInvoiceHistory(spType: number, skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('RentInvoice', 'GetRentInvoiceList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, SPType: spType, Search: searchTerm });
  }

  public getRentInvoiceDtl(id: number) {
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'GetRentInvoiceDtl')}`, { Id: id });
  }
  public getVehicleRentById(id: number) {
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'GetVehicleRentById')}`, { Id: id });
  }
public GetRentInvoiceById(id:number){
  return this.http.post(`${Config.getControllerUrl('RentInvoice', 'GetRentInvoiceById')}`, { Id: id });
}
  public RemoveDoc(id: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'RemoveDoc')}`, { Id: id });
  }
  public getItemInStock(x: string) {
    return this.http.post(`${Config.getControllerUrl('Store', 'GetItemInStock')}`, { Code: x });
  }

  public saveReturnRentedVehicel(code: number, date: any, remarks) {
    
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'saveReturnRentedVehicel')}`, { Id: code, Date: this._localService.dateTransFuckDate(date) ,Code:remarks});
  }
  public getMinDateToReturnVeh(code: number) {
    
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'GetMinDateToReturnVeh')}`, { Id: code });
  }

  public DeleteRentedVehicle(id: number) {
    
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'DeleteRentedVechel')}`, { Id: id });
  }
  public DeleteRentedVehicleInvoice(code: number) {
    return this.http.post(`${Config.getControllerUrl('RentedVehicle', 'DeleteRentedVehicleInvoice')}`, { Id: code });
  }
  public DeleteRentedAgreementVehicleInvoice(code: number) {
    return this.http.post(`${Config.getControllerUrl('RentInvoice', 'RemoveRentInvoice')}`, { Id: code });
  }
  public getAccountNatuers() {
    return this.http.get(`${Config.getControllerUrl('Accounts', 'GetAccountNatuers')}`);
  }
  public RemoveCatlog(id: number) {
    return this.http.post(`${Config.getControllerUrl('Store', 'RemoveCatlog')}`, { Id: id });
  }
  public getExpHeads() {
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetExpHeads')}`);
  }

  // item depreciation
  public ItemDepreciationList(skip: number, pageSize: number, searchTerm: string ) {
    return this.http.post(`${Config.getControllerUrl('ItemDepreciation', 'ItemDepreciationList')}`, {Skip: skip, PageSize: pageSize, Search: searchTerm});
  }

  public SaveItemDepreciation(obj: DepreciationViewModel) {
    this._localService.dateTransFuckDatesInObject(obj);
    let fd = new FormData();
    fd.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('ItemDepreciation', 'SaveItemDepreciation')}`, fd);
  }
  public DeleteItemDepreciation(id: number) {
    return this.http.post(`${Config.getControllerUrl('ItemDepreciation', 'DeleteItemDepreciation')}`, { Id: id });
  }
  public getInvoiceUrl(obj: Sale_PurchaseMastViewModel) {
    
    const formData = new FormData();
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Store', 'getIvoicePdfUrl')}`, formData);
  }

  public CheckItemCodeByExcel(file:any){
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(
      `${Config.getControllerUrl("store", "CheckItemCodeByExcel")}`,
      formData
    );
  }

  public SaveItemDefiningByExcel(obj:any) {

    console.log(obj);
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Store", "SaveItemDefiningByExcel")}`,
      formData
    );
  }
}

