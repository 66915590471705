import { TemplatesComponent } from './templates/templates.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeadsDefinigComponent } from './heads-definig/heads-definig.component';
import { CategoryDefiningComponent } from './category-defining/category-defining.component';
import { AccNatuerDefiningComponent } from './acc-natuer-defining/acc-natuer-defining.component';
import { ExpHeadsComponent } from './exp-heads/exp-heads.component';
import { HeaderFooterComponent } from './header-footer/header-footer.component';
import { VisaExpenseDefiningComponent } from './visa-expense-defining/visa-expense-defining.component';
import { CountrySettingComponent } from './country-setting/country-setting.component';
import { FormRestrictionsComponent } from './form-restrictions/form-restrictions.component';
import { DepartmentsComponent } from './departments/departments.component';
import { CompanyComponent } from './company/company.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { AreaZoneComponent } from './area-zone/area-zone.component';
import { EmployeeStatusComponent } from './employee-status/employee-status.component';
import { InventoryStatusComponent } from './inventory-status/inventory-status.component';
import { CitySettingComponent } from './city-setting/city-setting.component';
import { InsuranceExpenseDefiningComponent } from './insurance-expense-defining/Insurance-expense-defining.component';

const routes: Routes = [
  { path: 'heads', component: HeadsDefinigComponent, data: { title: 'Add' } },
  { path: 'catrgories', component: CategoryDefiningComponent, data: { title: 'Add' } },
  { path: 'natuer', component: AccNatuerDefiningComponent, data: { title: 'Add' } },
  { path: 'exp-head', component: ExpHeadsComponent, data: { title: 'Add' } },
  { path: 'head-foot', component: HeaderFooterComponent, data: { title: 'Add' } },
  { path: 'visa-expense', component: VisaExpenseDefiningComponent, data: { title: 'Add' } },
  { path: 'Insurance-expense', component: InsuranceExpenseDefiningComponent, data: { title: 'Insurance Expense' } },
  { path: 'country-setting', component: CountrySettingComponent, data: { title: 'Add'} },
  { path: 'form-restriction', component: FormRestrictionsComponent, data: { title: 'Add'} },
  { path: 'departments', component: DepartmentsComponent, data: { title: 'Add'} },
  { path: 'company', component: CompanyComponent, data: { title: 'Add'} },
  { path: 'warehouse', component: WarehouseComponent, data: { title: 'Add'} },
  { path: 'departments', component: DepartmentsComponent, data: { title: 'Add'}},
  {path:'area-zone', component:AreaZoneComponent, data:{title:'Area Zone'}},
  {path:'employee-status', component:EmployeeStatusComponent, data:{title:'Employeee Status'}},
  {path:'inventory-status', component:InventoryStatusComponent, data:{title:'Inventory Status'}},
  {path:'city-setting', component:CitySettingComponent, data:{title:'City Setting'}},
  {path:'templates', component:TemplatesComponent, data:{title:'Templates'}},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
