import { Component, OnInit } from '@angular/core';
import { BullkEBPOSReturnViewModel, EBOrdersViewModel } from '../eb-orders-class';
import { Subject, Subscription } from 'rxjs';
import { StoreService } from '../../store/store.services';
import { EmployeeServices } from '../../employee/employee-services';
import { NotificationService } from '../../../_services/notification.service';
import { EbService } from '../eb-service.service';
import { ItemViewModel } from '../../store/store-model';
import { debounceTime } from 'rxjs/operators';
import { EBOrderSettlementType } from '../../../_helpers/EB-enum';
import $ from "jquery";
import { Location } from '@angular/common';
@Component({
  selector: 'app-bulk-eb-cash',
  templateUrl: './bulk-eb-cash.component.html',
  styleUrls: ['./bulk-eb-cash.component.scss']
})
export class BulkEbCashComponent implements OnInit {
  public _BullkEBPOSReturnViewModel = new BullkEBPOSReturnViewModel();
  ebAccountList: Array<any> = [];
  paymentAccountList: Array<any> = [];
  public searchEvent = new Subject<any>();
  public searchSubcription: Subscription = null;
  fetchedEbOrders: Array<EBOrdersViewModel> = [];
  isMaxValue:boolean = false;
  employeeAccount:any;
toDate = new Date();
  fetchSpinner: boolean = false;
  public loading: boolean = false;
  public maxDate = new Date();
  SettlementTypes:Array<any> =[
    {
      Id:1,
      Type:'POS'
    },
    {
      Id:2,
      Type:'Cash'
    },
    {
      Id:3,
      Type:'Bank'
    }
  ]
  SettlementType:any;
  constructor(
    private _storeService: StoreService,
    private _employeeService: EmployeeServices,
    private _notificationService: NotificationService,
    private _ebOrderService: EbService,
    private location: Location
  ) {
    this._BullkEBPOSReturnViewModel.CreditAccount = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.FK_Tasker_Id = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.DebitAccount = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.FK_Customer_Id = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.Fk_BankAccount = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.GatewayExpenditureAccount = new ItemViewModel();
    this._BullkEBPOSReturnViewModel.FK_Client_Id = new ItemViewModel();
  }

  ngOnInit() {
    this.maxDate.setHours(this.maxDate.getHours() + 5);
    this._BullkEBPOSReturnViewModel.OrderDate = new Date();
    this.handleSearchEvents();
    this.getEBAccountList('','E');
    this.getPaymentAccount('','CaB');
  }
  public getEBAccountList(x: string, event: string) {
    this._storeService.getAccountBySearch(x, event).subscribe(
      (res: any) => {
        console.log("emp list", res);
        if (res) {
          if (event === "E") {
            this.ebAccountList = res;
          }
        }
      },
      (err) => {
        this._notificationService.notify('danger', err.msg);
      }
    );
  }
  public getPaymentAccount(x: string, event: string) {
    this._storeService.getAccountBySearch(x, event).subscribe(
      (res: any) => {
        console.log("cashbank acout", res);
        if (res) {
          if (event === "CaB") {
            this.paymentAccountList = res;
          }
        }
      },
      (err) => {
        this._notificationService.notify("danger", err.msg);
      }
    );
  }
  public handleSearchEvents() {
    this.searchSubcription = this.searchEvent.pipe(
      debounceTime(400)).subscribe((x: any) => {
        console.log(x);
        if (x) {
          if (x.filter == 'FK_Emp_Acc') {
            this.getEBAccountList(x.event, 'E');
          }
          if (x.filter == 'FK_Dabit_Acc') {
            this.getPaymentAccount(x.event, 'CaB');
          }
        }
      });
  }
  public attachfile(event) {
    this._BullkEBPOSReturnViewModel.file = event.target.files[0];
  }
  fetchBulkEBReturns(){
    this.fetchSpinner = true;
    
   let fetchObj ={
     Id:this.employeeAccount.Id,
     ToDate:this.toDate
   }
    this._ebOrderService
      .GetUnPaidEBOrdersCashByEmployeeId(fetchObj)
      .subscribe((ebOrders:any) => {
        console.log("fetched list", ebOrders);
        this._BullkEBPOSReturnViewModel = ebOrders;
        this.fetchedEbOrders = ebOrders.bulkPaymentList;
        this._BullkEBPOSReturnViewModel.OrderDate = new Date();
    
        this.fetchSpinner = false;
      },error => {
        this.fetchSpinner = false;
      });
  }
  public index:number;
  removelistElement(index) {
    this.index = index;
    const x = this.fetchedEbOrders[this.index];
    // this._BullkEBPOSReturnViewModel.TotalOrderAmount -= x.OrderAmount;
    // this._BullkEBPOSReturnViewModel.TotalEBCharges -=x.EBCharges;
    this.fetchedEbOrders.splice(this.index,1);
  }
  calculateTotals(){
    
   this._BullkEBPOSReturnViewModel.TotalRecevedFrombank=0;
   this._BullkEBPOSReturnViewModel.TotalEBCharges =0;
   this.fetchedEbOrders.forEach(x=>{

    if(x.EBCharges > x.EBChargesCheck){
      this.isMaxValue = true;
    }
    else{
      this.isMaxValue=false;
    }
    // this._BullkEBPOSReturnViewModel.TotalEBCharges += x.EBCharges;
   });
  }
  checkValidation(x){
    $('#Validation').on('keyup keydown', function(e){
      console.log($(this).val() > x.EBChargesCheck)
          if ($(this).val() > x.EBChargesCheck 
              && e.keyCode !== 46
              && e.keyCode !== 8
             ) {
             e.preventDefault();     
             $(this).val(x.EBChargesCheck);
          }
      });
  }
  AddBulkEBCash() {
    if (
      this.fetchedEbOrders.find((x) => x.IsLock == false) !=
      null
    ) {
      this.loading = false;
      this._notificationService.notify("danger", "Lock the all record first");
      return;
    }
    this._BullkEBPOSReturnViewModel.EmployeAccount_Id = this.employeeAccount;
      this._BullkEBPOSReturnViewModel.SettlementType = EBOrderSettlementType.Cash;
    this.loading = true;
    this._BullkEBPOSReturnViewModel.bulkPaymentList = this.fetchedEbOrders;
    let ebObject: any = Object.assign({}, this._BullkEBPOSReturnViewModel);
    if (this._BullkEBPOSReturnViewModel.OrderDate) {
      ebObject.OrderDate = this._BullkEBPOSReturnViewModel.OrderDate.toDateString();
    }
    this._ebOrderService.AddBulkEBCash(ebObject).subscribe(
      (res) => {
        this.loading = false;
        this._notificationService.notify("success", "Saved Successfully");
        this.location.back();
      },
      (err) => {
        console.log("error", err);
        this.loading = false;
        this._notificationService.notify("danger", "Something Went Wrong");
      }
    );
  }
lockRecord(x){
  if(x.EBCharges === null){
   this._notificationService.notify('danger','Please enter To pay amount to Lock the record');
   return ;
  }
  x.IsLock =!x.IsLock;
  if(x.IsLock){
    this._BullkEBPOSReturnViewModel.TotalEBCharges += x.EBCharges;
    this._BullkEBPOSReturnViewModel.TotalOrderAmount +=x.OrderAmount;
  }
  else{
    this._BullkEBPOSReturnViewModel.TotalEBCharges -= x.EBCharges;
    this._BullkEBPOSReturnViewModel.TotalOrderAmount -=x.OrderAmount;
  }
}
}
