import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent, A4LayoutComponent } from './containers';
import 'hammerjs';

const APP_CONTAINERS = [
  DefaultLayoutComponent,
  A4LayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppSidebarModule,
  AppFooterModule
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { P404Component } from './_directives/error/404.component';
import { NotificationComponent } from './_directives/notification.component';
import { HttpClientModule } from '@angular/common/http';
import { AfterValueChangedDirective } from './_directives/after-value-changed.directive';
import { P500Component } from './_directives/error/500.component';
import { SharedModule } from './shared.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ApplicationFtsLivePreviewComponent } from './_directives/application-fts-live-preview/application-fts-live-preview.component';
import { SettingsModule } from './modules/settings/settings.module';
import { CanDeactivateGuard } from './_guards/can-deactivate.guard';
import { LockedOutComponent } from './modules/user/locked-out/locked-out.component';
import { EmployeeServices } from './modules/employee/employee-services';
import { ErrandsBoysModule } from './modules/errands-boys/errands-boys.module';
import { InputsModule } from '@progress/kendo-angular-inputs';



@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    //FormsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppHeaderModule,
    AppSidebarModule,
    AppFooterModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DropDownsModule,
    DialogsModule,
    SettingsModule,
    ErrandsBoysModule,
    InputsModule
  ],
  declarations: [
    AppComponent,
    P404Component,
    NotificationComponent,
    LockedOutComponent,
    P500Component,
    ...APP_CONTAINERS,
    AfterValueChangedDirective,
    ApplicationFtsLivePreviewComponent
  ],
  providers: [
    /*     {
          provide: LocationStrategy,
          useClass: HashLocationStrategy
        } */
        DatePipe,
        CanDeactivateGuard,
        EmployeeServices
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
