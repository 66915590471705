import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { AuthGuard } from './_guards/auth.guard';
import { P404Component } from './_directives/error/404.component';
import { ApplicationFtsLivePreviewComponent } from './_directives/application-fts-live-preview/application-fts-live-preview.component';
import { LockedOutComponent } from './modules/user/locked-out/locked-out.component';
import { CanActivate } from '@angular/router/src/utils/preactivation';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'account',
    loadChildren: './account/account.module#AccountModule',
    data: { title: 'Login' }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: { title: 'Home' },
    children: [
      {
        path: 'dashboard',
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
        data: { title: 'Dashboard' },
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'stock',
      //   loadChildren: './modules/stock/stock.module#StockModule',
      //   canActivate: [AuthGuard],
      //   data: { title: 'Stock' }
      // },
      // {
      //   path: 'stock-reporting',
      //   loadChildren: './modules/stock-reporting/stock-reporting.module#StockReportingModule',
      //   canActivate: [AuthGuard],
      //   data: { title: 'Reporting' }
      // },
      {
        path: 'user',
        loadChildren: './modules/user/user.module#UserModule',
        canActivate: [AuthGuard],
        data: { title: 'User Management' }
      },
      {
        path: 'clients',
        loadChildren: './modules/clients/clients.module#ClientsModule',
        canActivate: [AuthGuard],
        data: { title: 'Client Management' }
      },
      {
        path: 'store',
        loadChildren: './modules/store/store.module#StoreModule',
        canActivate: [AuthGuard],
        data: { title: 'Store' }
      },
      {
        path: 'accounts',
        loadChildren: './modules/accounts/accounts.module#AccountsModule',
        canActivate: [AuthGuard],
        data: { title: 'Account' }
      },
      {
        path: 'employee',
        loadChildren: './modules/employee/employee.module#EmployeeModule',
        canActivate: [AuthGuard],
        data: { title: 'Employee' }
      },
      {
        path: 'errands-boys',
        loadChildren: './modules/errands-boys/errands-boys.module#ErrandsBoysModule',
        canActivate: [AuthGuard],
        data: { title: 'Errands Boys' }
      },
      {
        path: 'advances',
        loadChildren: './modules/advances/advances.module#AdvancesModule',
        canActivate: [AuthGuard],
        data: { title: 'Advance' }
      },
      {
        path: 'cheque',
        loadChildren: './modules/cheque/cheque.module#ChequeModule',
        canActivate: [AuthGuard],
        data: { title: 'Cheque' }
      },
      {
        path: 'reporting',
        loadChildren: './modules/reporting/reporting.module#ReportingModule',
        canActivate: [AuthGuard],
        data: { title: 'Reporting' }
      },
      {
        path: 'setting',
        loadChildren: './modules/settings/settings.module#SettingsModule',
        canActivate: [AuthGuard],
        data: { title: 'Setting' }
      }
      , {
        path: 'customer',
        loadChildren: './modules/coustomer/coustomer.module#CoustomerModule',
        canActivate: [AuthGuard],
        data: { title: 'Customer' }
      }

      , {
        path: 'emp-salary',
        loadChildren: './modules/emp-salary/emp-salary.module#EmpSalaryModule',
        canActivate: [AuthGuard],
        data: { title: 'Salary' }
      }
      , {
        path: 'expense',
        loadChildren: './modules/expense/expense.module#ExpenseModule',
        canActivate: [AuthGuard],
        data: { title: 'Expense' }
      },

    ]
  },
  {
    path: 'locked-out',
    component: LockedOutComponent
  },
  { path: '**', component: P404Component, data: { title: 'Not Found' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
