import { Injectable } from '@angular/core';
import { User } from '../_models/erp.class';
import { ClaimKeys } from '../_helpers/claimkeys.class';
import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class LocalService {
  public claimKeys = new ClaimKeys();
  constructor(private datePipe: DatePipe) {
  }

  public set(name: string, item: any) {
    localStorage.setItem(name, JSON.stringify(item));
  }
  public get(name: string) {
    return JSON.parse(localStorage.getItem(name));
  }

  public remove(name: string) {
    localStorage.removeItem(name);
  }

  public checkClaim(claim: string) {
    return this.get('ussr').Claims.includes(claim); // true;
  }

  public getDateString(d: Date) {
    return `${d.getMonth()}/${d.getDay()}/${d.getFullYear()}`;
  }

  public getDateWithoutTime(d: Date) {
    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0, 0, 0).toUTCString();
  }

  public dateTransFuckDate(d: Date) {
    return this.datePipe.transform(d, 'MM/dd/yyyy');
  }

  public dateTransFuckDatesInObject(obj) {

    // get all keys of an object
    let keys = Reflect.ownKeys(obj);
    // get keys that have 'date' in em
    let dateKeys = keys.filter(x => x.toString().toLowerCase().endsWith('date'));

    // transform the fuck out of it fuck it all up with reflection
    dateKeys.forEach(key => {
      Reflect.set(obj, key, this.dateTransFuckDate(Reflect.get(obj, key)));
    });


    // console.log(obj);
    // o.forEach(x => {
    //   console.log(x);
    // });

  }

}
