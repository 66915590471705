import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Config } from '../../_helpers/config.class';

import { AuthenticationService } from "../../_services/authentication.service";
import { Heads, Country, FormRestrictionDefiningViewModel, Department, Warehouse, Company } from "./setting.class";
import { Observable } from "rxjs/Observable";


@Injectable()
export class SettingServices {
  headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, _authenticationService: AuthenticationService) {
    this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    this.headers.set('Authorization', 'Bearer ' + _authenticationService.getToken());
  }
  public savePrehead(obj: Heads) {
    return this.http.post(`${Config.getControllerUrl('Setting', 'SaveHeads')}`, obj);
  }
  public RemoveHead(obj: number) {
    return this.http.post(`${Config.getControllerUrl('Setting', 'RemoveHead')}`, { Id: obj });
  }
  public getHeaderFooter() {
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetHeaderFooter')}`);
  }
  public saveHeaderFootrer(obj: any) {
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Setting', 'SaveHeaderFootrer')}`, formData);
  }


  public removeHeaderFootrerLetterHead(id: any) {
    return this.http.post(`${Config.getControllerUrl('Setting', 'RemoveHeaderFootrerLetterHead')}`, { Id: id });
  }
  public saveVisaExpenseHead(obj: any) {
    debugger
    return this.http.post(`${Config.getControllerUrl('VisaExpenseHead', 'SaveVisaExpenseHead')}`, obj);
  }

  public getVisaExpenseHeadList(): Observable<any> {
    return this.http.get(`${Config.getControllerUrl('VisaExpenseHead', 'GetVisaExpenseHeadList')}`);
  }

  public removeVisaExpenseHead(obj: any) {
    return this.http.post(`${Config.getControllerUrl('VisaExpenseHead', 'DeleteVisaExpenseHead')}`, obj);
  }
  public saveInsuranceExpenseHead(obj: any) {
    debugger
    return this.http.post(`${Config.getControllerUrl('InsuranceExpenseHead', 'SaveInsuranceExpenseHead')}`, obj);
  }

  public getInsuranceExpenseHeadList(): Observable<any> {
    return this.http.get(`${Config.getControllerUrl('InsuranceExpenseHead', 'GetInsuranceExpenseHeadList')}`);
  }

  public removeInsuranceExpenseHead(obj: any) {
    return this.http.post(`${Config.getControllerUrl('InsuranceExpenseHead', 'DeleteInsuranceExpenseHead')}`, obj);
  }



  public saveCountry(obj: Country) {
    debugger
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('GSettings', 'SaveG_Country')}`, formData);

  }

  public getList(){
    return this.http.get(`${Config.getControllerUrl('GSettings', 'GetG_CountryList')}`);
  }

  public getFormNamesList(){
    return this.http.get(`${Config.getControllerUrl('GSettings', 'GetFormRestrictionsList')}`);
  }


  public removeCountry(obj: any) {
    return this.http.post(`${Config.getControllerUrl('GSettings', 'DeleteG_Country')}`, obj);
  }

/////////////////City//////////////////////
public GetCitiesList(){
  return this.http.get(`${Config.getControllerUrl('Setting', 'GetCitiesList')}`);
}
public DeleteCities(obj: any) {
  return this.http.post(`${Config.getControllerUrl('Setting', 'DeleteCities')}`, obj);
}
public GetCitiesById(raw){
  return this.http.post(`${Config.getControllerUrl('Setting', 'GetCitiesById')}`,raw);
}

public SaveCities(obj:any){
  const formData = new FormData();
  formData.append('Obj', JSON.stringify(obj));
  return this.http.post(`${Config.getControllerUrl('Setting', 'SaveCities')}`, formData);
}
///////////////////////////////
  //Departments

  public getDeptList(){
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetDepartmentList')}`);
  }

  public getDeptById(){
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetDepartmentById')}`);
  }

  public saveDept(obj: Department){
    const formData = new FormData();
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Setting', 'SaveDepartment')}`, formData);
  }

  public deleteDept(obj: any){
    return this.http.post(`${Config.getControllerUrl('Setting', 'DeleteDepartment')}`, obj);
  }
  public GetHeaderFooterList(){
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetHeaderFooterList')}`);
  }
  //Company

  public getCompList(){
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetCompaniesList')}`);
  }

  public getCompById(){
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetCompaniesById')}`);
  }

  public saveComp(obj: Company){
    const formData = new FormData();
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Setting', 'SaveCompanies')}`, formData);
  }

  public deleteComp(obj: any){
    return this.http.post(`${Config.getControllerUrl('Setting', 'DeleteCompanies')}`, obj);
  }


  //Warehouse

  public getWhList(){
    return this.http.get(`${Config.getControllerUrl('GSettings', 'GetG_WareHouseList')}`);
  }

  public getWhById(){
    return this.http.get(`${Config.getControllerUrl('GSettings', 'GetG_WareHouseById')}`);
  }

  public saveWh(obj: Warehouse){
    return this.http.post(`${Config.getControllerUrl('GSettings', 'SaveG_WareHouse')}`, obj);
  }

  public deleteWh(obj: any){
    return this.http.post(`${Config.getControllerUrl('GSettings', 'DeleteG_WareHouse')}`, obj);
  }

  public GetCompaniesList(){
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetCompaniesList')}`);
  }
  ///////////Area Zone CRUD/////////////////
  public saveAreaZone(obj:any) {
    const formData = new FormData();
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Setting', 'SaveAreaZone')}`, formData);
  }
  public getAreaZoneList(){
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetAreaZoneList')}`);
  }
  public removeAreaZone(obj: any) {
    return this.http.post(`${Config.getControllerUrl('Setting', 'DeleteAreaZone')}`, obj);
  }
  public GetAreaZoneById(raw:any){
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetAreaZoneById')}`,raw);
  }
  /////////////////////////////////////////
  
  //////////////////Employee Status CRUD /////////////////////
  public getEmployeeStatus():Observable<any>{
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetEmployeStatusList')}`);
  }
  public DeleteEmployeStatus(obj: any) {
    return this.http.post(`${Config.getControllerUrl('Setting', 'DeleteEmployeStatus')}`, obj);
  }
  public GetEmployeStatusById(raw:any){
    return this.http.get(`${Config.getControllerUrl('Setting', 'GetEmployeStatusById')}`,raw);
  }
  public SaveEmployeStatus(obj:any) {
    const formData = new FormData();
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Setting', 'SaveEmployeStatus')}`, formData);
  }
  public ChangeStatus(obj:any) {
    // const formData = new FormData();
    // formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Setting', 'ChangeStatus')}`, obj);
  }
  public getEmployeHistory(id: number) {
    return this.http.post(
      `${Config.getControllerUrl(
        "Setting",
        "getEmployeHistory"
      )}`,
      { Id: id }
    );
  }
  ///////////////////////////////////////////////////////////
/////////////////////////////////  inventory Status ///////////////////////
public SaveInventoryStatus(obj:any) {
  debugger;
  const formData = new FormData();
  formData.append('Obj', JSON.stringify(obj));
  return this.http.post(`${Config.getControllerUrl('Setting', 'SaveInventoryStatus')}`, formData);
}
public SaveDocumentTemplatesList(obj:any) {
  debugger;
  const formData = new FormData();
  formData.append('Obj', JSON.stringify(obj));
  return this.http.post(`${Config.getControllerUrl('Setting', 'SaveDocumentTemplatesList')}`, formData);
}
public DeleteInventoryStatus(obj: any) {
  return this.http.post(`${Config.getControllerUrl('Setting', 'DeleteInventoryStatus')}`, obj);
}
public DeleteGetDocumentTemplatesList(obj: any) {
  return this.http.post(`${Config.getControllerUrl('Setting', 'DeleteGetDocumentTemplatesList')}`, obj);
}
public GetInventoryStatusById(raw:any){
  return this.http.post(`${Config.getControllerUrl('Setting', 'GetInventoryStatusById')}`,{Id:raw});
}
public GetInventoryStatusList(){
  return this.http.post(`${Config.getControllerUrl('Setting', 'GetInventoryStatusList')}`,{});
}
public GetDocumentTemplatesList(){
  return this.http.post(`${Config.getControllerUrl('Setting', 'GetDocumentTemplatesList')}`,{});
}
public ChangeInventoryStatus(obj:any) {
  return this.http.post(`${Config.getControllerUrl('Store', 'ChangeInventoryStatus')}`, obj);
}
public ChangeItemCities(obj:any) {
  return this.http.post(`${Config.getControllerUrl('Setting', 'ChangeItemCities')}`, obj);
}
public getItemCitiesHistory(id:any) {
  return this.http.post(`${Config.getControllerUrl('Setting', 'getItemCitiesHistory')}`, {Id:id});
}
ChangeCompaniesPreferance(AccountId){
  return this.http.post(`${Config.getControllerUrl('Setting', 'ChangeCompaniesPreferance')}`, {Id:AccountId});
}
getEmploye4plHistorybyId(EmployeeId){
  return this.http.post(`${Config.getControllerUrl('SupplierContract', 'GetAssingedEmpByEmpId')}`, { Skip: 0, PageSize: 100, AccId: EmployeeId});
}
public getAssinged4Pl(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, accId: number, searchTerm: string) {
  return this.http.post(`${Config.getControllerUrl('SupplierContract', 'GetAssingedEmp')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, AccId: accId , Search: searchTerm});
}
}
