﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from '../_services/cookie.service';
import { AuthenticationService } from '../_services/authentication.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private _authSvc: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = this._authSvc.getUser();
    let com = this._authSvc.getCom();
    if (com !== undefined && com !== null) {
      user.clientId = com.Id;
    }

    if (user !== undefined && user !== null) {

      return this._authSvc.isUserStampValid(user).pipe(
        map((e: any) => {
          console.log(e);
          // if (e.IsBlock == true) {
          //   this.router.navigate(['/locked-out']);
          //   return false
          // }

          if (e.isStampValid == true) {
            let ret = true;
            if (e.IsBlock == true) {
              this.router.navigate(['/locked-out']);
              ret = false;
            }
            return ret;
          } else {
            this._authSvc.logout();
            return false;
          }
        }),
        catchError((err) => {
          this.router.navigate(['/account/login']);
          return of(false);
        })
      );
    } else {
      // not logged in so redirect to login page with the return url
      let url = state.url;
      if (state.url == '/dashboard') {
        this.router.navigate(['/account/login']);
      } else {
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
      }
      return false;
    }
    // console.log('activating');
    // let cookie: any = this._cookieService.getCookie('ussr');
    // // console.log(cookie);
    // if (cookie) {
    //   // logged in so return true
    //   // check for valid securirty stamp
    //   let res = false;
    //   this._authSvc.isUserStampValid().subscribe((res: any) => {
    //     // console.log(res);
    //     debugger;
    //     if (res.IsStampValid == true) {
    //       // console.log('successful');
    //       res= true;
    //     }
    //     if (res.IsStampValid == false)  {
    //       this._authSvc.logout();
    //       res = false;
    //     }
    //   }, (err) => {
    //     // this._authSvc.logout();
    //     // console.log('failed');
    //     // return false;
    //   });
    //   return res;
    // }
    // // not logged in so redirect to login page with the return url
    // let url = state.url;
    // if (state.url == '/dashboard') {
    //   this.router.navigate(['/account/login']);
    // } else {
    //   this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
    // }
    // return false;
  }

  // backup
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   let cookie: any = this._cookieService.getCookie('ussr');

  //   if (cookie) {
  //     // logged in so return true
  //     return true;
  //   }
  //   // not logged in so redirect to login page with the return url
  //   let url = state.url;
  //   if (state.url == '/dashboard') {
  //     this.router.navigate(['/account/login']);
  //   } else {
  //     this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
  //   }
  //   return false;
  // }
}
